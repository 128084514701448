import type { DrawerSettings } from '@skeletonlabs/skeleton';
import type { FEAllPublishers } from '@tickrr/db';
import type { DrawerStore } from '@tickrr/ui/types';

import { defaultDrawerSettings } from '@tickrr/ui/components/v2/elements/drawer/defaultSettings.ts';

export type UpdatePublisherDrawerMeta = {
	initialPublisherData: FEAllPublishers[number];
	refetchPublishers: () => Promise<unknown>;
};

export const updatePublisherDrawerSettings: DrawerSettings = {
	...defaultDrawerSettings.right,
	id: 'back-office:update-publisher-drawer'
};

export const openUpdatePublisherDrawer = (
	drawerStore: DrawerStore,
	meta?: UpdatePublisherDrawerMeta
) => {
	drawerStore.open({ ...updatePublisherDrawerSettings, meta });
};
