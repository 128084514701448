import type { DrawerSettings } from '@skeletonlabs/skeleton';
import type { DrawerStore } from '@tickrr/ui/types';

import { defaultDrawerSettings } from '@tickrr/ui/components/v2/elements/drawer/defaultSettings.ts';

export type AddPublisherDrawerMeta = {
	refetchPublishers: () => Promise<unknown>;
};

export const addPublisherDrawerSettings: DrawerSettings = {
	...defaultDrawerSettings.right,
	id: 'back-office:add-publisher-drawer'
};

export const openAddPublisherDrawer = (drawerStore: DrawerStore, meta?: AddPublisherDrawerMeta) => {
	drawerStore.open({ ...addPublisherDrawerSettings, meta });
};
